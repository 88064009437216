import React, { useState, useEffect, useRef } from "react";
import { useCollection, useDoc } from "../../hooks/useFirebase";

import Participant from "./Participant";

export default function MatchCardsPane({ performancePath }) {
  const { data: performance } = useDoc(performancePath);

  const { data: participants, update: updateParticipant } = useCollection(
    `${performancePath}/participants/`
  );

  const [phonesByKey, setPhonesByKey] = useState(null);
  const { data: contacts } = useCollection(`${performancePath}/contacts/`);
  useEffect(() => {
    if (!contacts) return;
    const newPhonesByKey = {};
    contacts.forEach((contact) => {
      if (contact && contact.card && contact.card.key) {
        const key = contact.card.key;
        const phoneNumber = contact.from;
        newPhonesByKey[key] = phoneNumber;
      }
    });
    setPhonesByKey(newPhonesByKey);
  }, [contacts]);

  let gridPositions = 49;
  if (performance && performance.rows && performance.cols)
    gridPositions = parseInt(performance.rows) * parseInt(performance.cols);

  return (
    <div>
      <ul>
        {participants &&
          participants
            .sort((a, b) => (a.index || 99) - (b.index || 99))
            .map((participant) => (
              <Participant
                key={participant.id}
                participant={participant}
                participantPath={
                  performancePath + "/participants/" + participant.id
                }
                update={updateParticipant}
                phonesByKey={phonesByKey}
                gridPositions={gridPositions}
              />
            ))}
      </ul>
    </div>
  );
}
