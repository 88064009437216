import React, { useState, useEffect, useRef } from "react";
import { useDoc } from "../../hooks/useFirebase";

import SetupForm from "./SetupForm";

export default function SetupPane({ performancePath }) {
  const { data: performance, debouncedUpdate } = useDoc(performancePath);

  const { rows, cols, mosaicSrc } = performance || {};

  // 16:9

  return (
    <div>
      {performance && (
        <SetupForm
          performance={performance}
          performancePath={performancePath}
          onChange={(newData) => debouncedUpdate(newData)}
        />
      )}
    </div>
  );
}

// Object.values(screenshots)
//   .sort((a, b) => a.createdAt - b.createdAt)
//   .map(({ id, src }) => <img key={id} className="w-32 mr-2" src={src} />);
