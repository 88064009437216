import React, { useState, useEffect } from "react";
import { useCollection, useDoc, useFirestore } from "../../hooks/useFirebase";
import { formatDistanceToNow } from "date-fns";

function BlastTextForm({ contacts, performancePath }) {
  const db = useFirestore();
  const userPath = performancePath.split("/performances")[0];
  const conversationsPath = `${userPath}/conversations`;
  const [body, setBody] = useState("");
  const [sending, setSending] = useState(false);

  return (
    <div>
      <p>Blast a text to everyone:</p>
      <textarea
        value={body}
        onChange={(e) => setBody(e.target.value)}
        className="w-full shadow p-1"
      ></textarea>
      <button
        onClick={async () => {
          if (sending) return;
          setSending(true);
          await Promise.all(
            contacts.map(({ id }) => {
              const messagesPath = `${conversationsPath}/${id}/messages`;
              const message = { createdAt: new Date(), body };
              // if (id === "+18177977334") {
              // console.log({ message, messagesPath });
              return db.collection(messagesPath).add(message);
              // }
            })
          );
          setSending(false);
          setBody("");
        }}
        className="bg-green-500 text-white rounded px-2 py-1 w-full"
      >
        {sending ? "Sending..." : "Send"}
      </button>
    </div>
  );
}

export default function BlastCardsPane({ performancePath }) {
  const { data: contacts } = useCollection(`${performancePath}/contacts/`, {
    // orderBy: "index",
    // desc: true,
  });

  return (
    <div>
      <ol className="list-decimal ml-4">
        {contacts &&
          contacts
            .sort((a, b) => a.index - b.index)
            .map(({ id, index, createdAt, card }) => (
              <li key={id} className="mb-2">
                {id}{" "}
                {card && (
                  <span
                    className={`${
                      card.key.match(/[cs]/) ? "bg-black" : "bg-red-600"
                    } rounded text-white p-1`}
                  >
                    {card.key}
                  </span>
                )}{" "}
                <span className="text-gray-500">
                  {formatDistanceToNow(createdAt.toDate())} ago
                </span>
              </li>
            ))}
      </ol>
      <BlastTextForm contacts={contacts} performancePath={performancePath} />
    </div>
  );
}
