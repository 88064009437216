import React from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";

import Shell from "../components/Shell";
import { useCollection, useDoc } from "../hooks/useFirebase";

import PerformanceTabs from "../components/Performance/PerformanceTabs";
import SetupPane from "../components/Performance/SetupPane";
import BlastCardsPane from "../components/Performance/BlastCardsPane";
import RecordParticipantsPane from "../components/Performance/RecordParticipantsPane";
import MatchCardsPane from "../components/Performance/MatchCardsPane";
import BlastMosaicPane from "../components/Performance/BlastMosaicPane";

import PerformerRecordButton from "../components/Performance/PerformerRecordButton";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PerformancePage({ userId }) {
  const { performanceId } = useParams();
  const query = useQuery();
  const magicianId = query.get("magicianId");

  const userData = null;

  const magicianPath = magicianId
    ? `/users/${magicianId}/`
    : `/users/${userId}/`;

  const performancePath = `${magicianPath}performances/${performanceId}`;

  const {
    data: performance,
    update: updatePerformance,
    debouncedUpdate: update,
    remove,
  } = useDoc(performancePath);

  const tabs = [
    { title: "1. Setup", id: `setup` },
    { title: "2. Watch SMS coming in", id: `cardblast` },
    { title: "3. Record Participants with Cards", id: `record` },
    { title: "4. Match Cards to Participants", id: `match` },
    { title: "5. Blast Mosaic", id: `mosaicblast` },
  ];
  tabs.forEach((tab) => {
    tab.routePath = `/${performanceId}/${tab.id}`;
    tab.path = tab.routePath + (magicianId ? `?magicianId=${magicianId}` : "");
  });

  if (!performance) return <Shell title="Performance">Loading...</Shell>;

  return (
    <Shell
      title={performance.title}
      onChange={(newTitle) => update({ title: newTitle })}
    >
      <PerformerRecordButton performancePath={performancePath} />
      <PerformanceTabs tabs={tabs} />
      <Switch>
        <Route path={tabs[0].routePath}>
          <SetupPane
            // performance={performance}
            // userData={userData}
            // updatePerformance={updatePerformance}
            performancePath={performancePath}
          />
        </Route>
        <Route path={tabs[1].routePath}>
          <BlastCardsPane performancePath={performancePath} />
        </Route>
        <Route path={tabs[2].routePath}>
          <RecordParticipantsPane performancePath={performancePath} />
        </Route>
        <Route path={tabs[3].routePath}>
          <MatchCardsPane performancePath={performancePath} />
        </Route>
        <Route path={tabs[4].routePath}>
          <BlastMosaicPane
            magicianPath={magicianPath}
            performancePath={performancePath}
          />
        </Route>
      </Switch>
    </Shell>
  );
}
