import React, { useState, useEffect, useRef } from "react";
import useInterval from "../../hooks/useInterval";
import { useStorage, useCollection } from "../../hooks/useFirebase";

function captureFrame({ video, canvas }) {
  return new Promise((resolve, reject) => {
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;

    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(async (blob) => {
      resolve(blob);
    });
  });
}

export default function RecordParticipantsPane({ performancePath }) {
  const { upload } = useStorage();
  const { data: assistantScreenshots, add: addScreenshot } = useCollection(
    `${performancePath}/assistantScreenshots/`,
    {
      limit: 14,
      orderBy: "createdAt",
      desc: true,
    }
  );

  const [recording, setRecording] = useState(false);

  const videoRef = useRef();
  const canvasRef = useRef();

  const [screenshots, setScreenshots] = useState({});

  useInterval(async () => {
    if (recording) {
      const blob = await captureFrame({
        canvas: canvasRef.current,
        video: videoRef.current,
      });

      // upload the frame
      const createdAt = new Date();
      const id = +createdAt;
      const path = `${performancePath}/assistantScreenshots/${id}.png`;
      const screenshot = { id, createdAt, src: URL.createObjectURL(blob) };

      // setScreenshots({ ...screenshots, [id]: screenshot });

      const { url } = await upload({
        data: blob,
        path,
      });
      // setScreenshots({ ...screenshots, [id]: { ...screenshot, src: url } });
      addScreenshot({ ...screenshot, src: url });

      // TODO: save this screenshot in firestore
      // console.log(blob);
    }
  }, 1000);

  return (
    <div>
      {!recording && (
        <button
          className="bg-green-500 px-2 py-3 rounded hover:bg-green-400 text-white"
          onClick={async () => {
            const captureStream = await navigator.mediaDevices.getDisplayMedia(
              {}
            );
            videoRef.current.srcObject = captureStream;
            setRecording(true);
          }}
        >
          Record "Zoom Meeting" Application Window when participants hold their
          cards up
        </button>
      )}
      {recording && (
        <button
          className="bg-red-500 px-2 py-3 rounded hover:bg-red-400 text-white"
          onClick={() => {
            videoRef.current.srcObject
              .getTracks()
              .forEach((track) => track.stop());
            setRecording(false);
          }}
        >
          Stop Recording
        </button>
      )}
      <p>
        Page through each page of the Gallery View and make sure there's a clear
        black border around the window. Try to capture everyone holding up their
        cards, and hit "Stop Recording" above when you think you've captured a
        clear image of everyone's card.
      </p>
      <div className="mb-4"></div>
      <video
        className={recording ? "hidden" : "hidden"}
        autoPlay
        ref={videoRef}
      ></video>
      <canvas className="hidden" ref={canvasRef}></canvas>

      <div className="flex flex-wrap">
        {assistantScreenshots &&
          assistantScreenshots.map(({ id, src }) => (
            <img key={id} className="w-32 mr-2" src={src} />
          ))}
      </div>
    </div>
  );
}

// Object.values(screenshots)
//   .sort((a, b) => a.createdAt - b.createdAt)
//   .map(({ id, src }) => <img key={id} className="w-32 mr-2" src={src} />);
