import React from "react";

import Shell from "../components/Shell";
import { useCollection, useDoc } from "../hooks/useFirebase";

import PerformancesList from "../components/PerformancesList";

function MagicianPerformances({ magicianId }) {
  const { data: magician } = useDoc(`/users/${magicianId}`);
  const { data: performances, add: addPerformance } = useCollection(
    `/users/${magicianId}/performances`,
    {
      orderBy: "createdAt",
      desc: true,
    }
  );

  return (
    <div>
      {magician && (
        <h1 className="text-xl mt-4 mb-2">
          {magician.displayName}'s performances • {magician.phoneNumber}
        </h1>
      )}
      <PerformancesList performances={performances} magicianId={magicianId} />
    </div>
  );
}

export default function PerformancesPage({}) {
  const { data: userData } = useDoc("");
  const {
    data: performances,
    add: addPerformance,
  } = useCollection("performances", { orderBy: "createdAt", desc: true });

  const { magicianIds } = userData || {};

  return (
    <Shell title="Performances">
      <button
        className="rounded px-3 py-2 bg-green-500 text-white mb-4"
        onClick={() => {
          const title = prompt("What's this performance's name?");
          if (title) addPerformance({ title });
        }}
      >
        New Performance
      </button>

      {userData && (
        <h1 className="text-xl mt-4 mb-2">{userData.phoneNumber}</h1>
      )}
      <PerformancesList performances={performances} />

      {magicianIds &&
        magicianIds.map((magicianId) => (
          <MagicianPerformances key={magicianId} magicianId={magicianId} />
        ))}
    </Shell>
  );
}
