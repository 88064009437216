import React, { useState } from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";

import Shell from "../components/Shell";
import { useCollection, useDoc } from "../hooks/useFirebase";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CreateMessageField({ addMessage }) {
  const [body, setBody] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addMessage({ body });
        setBody("");
      }}
    >
      <textarea
        className="bg-gray-200 w-full p-3"
        placeholder={"Type a message..."}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <button
        className="rounded p-2 bg-blue-500 text-white w-full"
        type="submit"
      >
        Send
      </button>
    </form>
  );
}

export default function ConversationPage({}) {
  const { conversationId } = useParams();
  const query = useQuery();
  const magicianId = query.get("magicianId");

  const userData = null;

  let conversationPath = magicianId ? `/users/${magicianId}/` : "";
  conversationPath += `conversations/${conversationId}`;

  const {
    data: conversation,
    update: updateConversation,
    debouncedUpdate: update,
    remove,
  } = useDoc(conversationPath);

  const { data: messages, add: addMessage } = useCollection(
    conversationPath + "/messages",
    { orderBy: "createdAt" }
  );

  if (!conversation) return <Shell title="Performance">Loading...</Shell>;

  return (
    <Shell
      title={conversation.title || conversation.id}
      onChange={(newTitle) => update({ title: newTitle })}
    >
      <div>
        {messages &&
          messages.map(({ body, mediaUrls, id, sentAt, receivedAt }) => (
            <div key={id} className="clearfix">
              <div
                className={`${
                  receivedAt
                    ? "bg-gray-200"
                    : "bg-blue-400 text-white float-right"
                } rounded p-2 w-1/3 mb-1`}
                key={id}
              >
                {body}
                {mediaUrls &&
                  mediaUrls.map((url) => <img key={url} src={url} />)}
              </div>
            </div>
          ))}
      </div>
      <CreateMessageField
        addMessage={(message) => {
          addMessage(message);
          // updateConversation({ noAutoreply: true });
        }}
      />

      {false && (
        <div className="mt-4 flex items-start">
          <div className="flex items-center h-5">
            <input
              id="noAutoreply"
              type="checkbox"
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              checked={conversation.noAutoreply}
              onChange={(e) => {
                updateConversation({ noAutoreply: e.target.checked });
              }}
            />
          </div>

          <div className="ml-3 text-sm leading-5">
            <label htmlFor="noAutoreply" className="font-medium text-gray-700">
              No autoreply
            </label>
            <p className="text-gray-500">
              {conversation.noAutoreply
                ? "ZoomReveal won't automatically send this person a card when they send you a text."
                : "ZoomReveal will automatically text this person a card when they send you a text."}
            </p>
          </div>
        </div>
      )}
    </Shell>
  );
}
