import React from "react";
import Menu from "./Menu";
import EditableText from "./EditableText";

export default function Shell({ children, title, onChange }) {
  return (
    <div>
      <div className="bg-gray-800 pb-32">
        <Menu />
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1>
              {typeof onChange === "function" ? (
                <EditableText
                  className="w-full text-3xl leading-9 font-bold text-white bg-transparent"
                  type="text"
                  value={title}
                  onChange={onChange}
                />
              ) : (
                <span className="w-full text-3xl leading-9 font-bold text-white bg-transparent">
                  {title}
                </span>
              )}
            </h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}
