export default {
  apiKey: "AIzaSyATd-hr4bPfjohcICeuWBdzNl7CaVfP1_U",
  authDomain: "zoomreveal-140dd.firebaseapp.com",
  databaseURL: "https://zoomreveal-140dd.firebaseio.com",
  projectId: "zoomreveal-140dd",
  storageBucket: "zoomreveal-140dd.appspot.com",
  messagingSenderId: "502881128092",
  appId: "1:502881128092:web:be490d4dd4b202499ba759",
  measurementId: "G-VYDV9SL0WE",
};

// export default {
//   apiKey: "AIzaSyBMhOOuX-V9Sm8lM2CHWjZUPOCx1UmmCyU",
//   authDomain: "zoomreveal-54497.firebaseapp.com",
//   databaseURL: "https://zoomreveal-54497.firebaseio.com",
//   projectId: "zoomreveal-54497",
//   storageBucket: "zoomreveal-54497.appspot.com",
//   messagingSenderId: "291992027788",
//   appId: "1:291992027788:web:df1188beda1ea2760d31a9",
// };
