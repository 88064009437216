import React, { useState } from "react";
import { useStorage } from "../../hooks/useFirebase";

export default function SetupForm({ performance, onChange, performancePath }) {
  const { upload } = useStorage();
  let { mosaicSrc, rows, cols, mosaicGrid } = performance;

  const [progress, setProgress] = useState({});
  const { total, transferred, percent } = progress;

  mosaicGrid || (mosaicGrid = {});
  const isMosaicGridCurrent =
    mosaicGrid.rows === rows &&
    mosaicGrid.cols === cols &&
    mosaicGrid.mosaicSrc === mosaicSrc;

  return (
    <div>
      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
        <label
          htmlFor="cover_photo"
          className="block text-sm leading-5 font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Mosaic Image
        </label>
        <div className="mt-2 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="mt-1 text-sm text-gray-600">
                <div className="relative overflow-hidden">
                  <button
                    type="button"
                    className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                  >
                    Upload a file
                  </button>
                  <input
                    type="file"
                    className="absolute top-0 left-0 opacity-0"
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      const path = performancePath + "/" + file.name;
                      const { url } = await upload({
                        data: file,
                        path,
                        onProgress: ({ total, transferred, percent }) => {
                          setProgress({ total, transferred, percent });
                        },
                      });
                      onChange({ mosaicSrc: url });
                    }}
                  />
                </div>{" "}
                {(!percent || percent) === 100 &&
                  "or drag and drop in the future but that doesn't work yet so click Upload a file instead lol."}
                {percent && percent !== 100 && `${percent}% uploaded...`}
              </div>
              <p className="mt-1 text-xs text-gray-500">PNG or JPG</p>
            </div>
          </div>
          {mosaicSrc && <img className="max-w-lg" src={mosaicSrc} alt="" />}
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="rows"
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        >
          Rows
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <input
              id="rows"
              type="number"
              min="1"
              max="7"
              value={"" + rows}
              onChange={(e) => onChange({ rows: +e.target.value })}
              placeholder="3"
              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="cols"
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        >
          Cols
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <input
              id="cols"
              type="number"
              min="1"
              max="7"
              value={"" + cols}
              onChange={(e) => onChange({ cols: +e.target.value })}
              placeholder="3"
              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
        <span className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
          Preview
        </span>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="rounded-md shadow-sm">
            <pre>
              {JSON.stringify(
                {
                  mosaicGrid: !!mosaicGrid,
                  isMosaicGridCurrent,
                  mosaicSrc: !!mosaicGrid.mosaicSrc,
                },
                null,
                2
              )}
            </pre>
            {mosaicGrid && mosaicGrid.mosaicSrc && isMosaicGridCurrent && (
              <table className="w-full">
                <tbody>
                  {[...Array(mosaicGrid.rows)].map((_, r) => (
                    <tr key={`row-${r}`}>
                      {[...Array(mosaicGrid.cols)].map((_, c) => (
                        <td key={`r${r}c${c}`}>
                          <img
                            className={``}
                            key={`img-r${r}c${c}`}
                            src={mosaicGrid.pieceSrcs[r * mosaicGrid.cols + c]}
                            alt=""
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
