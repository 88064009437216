import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";

export default function PerformanceTabs({ tabs }) {
  return (
    <div className="block mb-4">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex">
          {tabs.map(({ title, path }, i) => (
            <NavLink
              key={path}
              to={path}
              className={`${
                i !== 0 ? "ml-7" : ""
              } whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300`}
              activeClassName="border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700"
            >
              {title}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
