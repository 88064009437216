import React, { useState, useEffect, useRef } from "react";
import { useCollection, useDoc } from "../../hooks/useFirebase";

import EditableText from "../EditableText";

function Images({ imagesPath }) {
  const { data: images } = useCollection(imagesPath, {});

  return (
    <div className="flex flex-wrap ml-8">
      {!images && "Loading images..."}
      {images &&
        images.map(({ id, src }) => (
          <img key={id} src={src} className="max-w-xs" />
        ))}
    </div>
  );
}

export default function Participant({
  participant,
  update,
  participantPath,
  phonesByKey,
  gridPositions,
}) {
  const { id, title, cardKey, index } = participant;
  const [expanded, setExpanded] = useState(false);
  const phoneNumber = phonesByKey && phonesByKey[cardKey];

  const indexOptions = [...new Array(gridPositions)].map((_, i) => i + 1);
  indexOptions.unshift("");

  return (
    <li className={`mb-4 ${index ? "bg-green-100" : ""}`}>
      <div className="">
        <select
          className="mr-2 bg-transparent"
          value={index || ""}
          onChange={(e) =>
            update({ ...participant, index: parseInt(e.target.value) })
          }
        >
          {indexOptions.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </select>
        <EditableText
          singleLine={true}
          className="p-1 w-32 mr-2 bg-transparent"
          placeholder="card key"
          value={cardKey}
          onChange={(cardKey) => update({ ...participant, cardKey })}
          onFocus={() => setExpanded(true)}
          onBlur={() => setExpanded(false)}
        />

        <span className="font-bold mr-2">{title}</span>
        {phoneNumber && (
          <span className="bg-green-200 p-1 text-green-900 rounded float-right">
            {phoneNumber}
          </span>
        )}
      </div>
      {expanded && <Images imagesPath={participantPath + "/images"} />}
    </li>
  );
}
