import React, { useState, useRef, useEffect } from "react";

export default function useScreenRecorder() {
  const [isRecording, setIsRecording] = useState(false);

  const videoRef = React.useRef(document.createElement("video"));
  const canvasRef = React.useRef(document.createElement("canvas"));

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    video.autoplay = true;
    video.style.display = "none";
    // video.src = "https://www.w3schools.com/html/mov_bbb.mp4";
    document.body.appendChild(video);
  });

  async function start() {
    const captureStream = await navigator.mediaDevices.getDisplayMedia({});
    videoRef.current.srcObject = captureStream;
    setIsRecording(true);
  }

  function stop() {
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    setIsRecording(false);
  }

  function captureFrame() {
    console.log("Capturing frame");
    const video = videoRef.current;
    const canvas = canvasRef.current;
    return new Promise((resolve, reject) => {
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;

      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  }

  return { isRecording, start, stop, captureFrame };
}

// const [screenshots, setScreenshots] = useState({});

// const blob = await captureFrame({
//   canvas: canvasRef.current,
//   video: videoRef.current,
// });

// <video
//   className={recording ? "hidden" : "hidden"}
//   autoPlay
//   ref={videoRef}
// ></video>
// <canvas className="hidden" ref={canvasRef}></canvas>
