import React, { Fragment, useState, useEffect } from "react";
// import { format, formatISO, parseISO } from "date-fns";

import { useAuth } from "./hooks/useFirebase";

import Shell from "./components/Shell";

import { Switch, Route } from "react-router-dom";
import PerformancesPage from "./pages/PerformancesPage";
import PerformancePage from "./pages/PerformancePage";
import ConversationsPage from "./pages/ConversationsPage";
import ConversationPage from "./pages/ConversationPage";

export default ({}) => {
  const { user } = useAuth();

  if (!user) return <Shell title="Zoom Reveal">Please sign in.</Shell>;

  return (
    <Switch>
      <Route exact path="/">
        <PerformancesPage />
      </Route>
      <Route path="/conversations" exact>
        <ConversationsPage />
      </Route>
      <Route path="/conversations/:conversationId">
        <ConversationPage />
      </Route>
      <Route path="/:performanceId">
        <PerformancePage userId={user.uid} />
      </Route>
    </Switch>
  );
};
