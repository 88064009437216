import React from "react";
import Shell from "../components/Shell";
import { useCollection, useDoc } from "../hooks/useFirebase";

import ConversationsList from "../components/ConversationsList";

function MagicianConversations({ magicianId }) {
  const { data: magician } = useDoc(`/users/${magicianId}`);
  const { data: conversations } = useCollection(
    `/users/${magicianId}/conversations`,
    {
      orderBy: "newestMessage.createdAt",
      desc: true,
    }
  );

  return (
    <div>
      {magician && (
        <h1 className="text-xl mt-4 mb-2">
          {magician.displayName}'s conversations • {magician.phoneNumber}
        </h1>
      )}
      <ConversationsList
        conversations={conversations}
        magicianId={magicianId}
      />
    </div>
  );
}

export default function ConversationsPage({}) {
  const { data: userData } = useDoc("");
  const { data: conversations } = useCollection("conversations", {
    orderBy: "newestMessage.createdAt",
    desc: true,
  });

  const { magicianIds } = userData || {};

  return (
    <Shell title="Conversations">
      {userData && (
        <h1 className="text-xl mt-4 mb-2">{userData.phoneNumber}</h1>
      )}
      <ConversationsList conversations={conversations} />
      {magicianIds &&
        magicianIds.map((magicianId) => (
          <MagicianConversations key={magicianId} magicianId={magicianId} />
        ))}
    </Shell>
  );
}
