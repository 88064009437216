import React from "react";
import { Link } from "react-router-dom";

import { formatDistanceToNow } from "date-fns";

export default function PerformancesList({ performances, magicianId }) {
  if (!performances) return <p>Create a new performance</p>;
  return (
    <ul className="shadow rounded">
      {performances.map(({ title, id, createdAt }) => (
        <li key={id}>
          <Link
            to={
              magicianId
                ? `/${id}/setup?magicianId=${magicianId}`
                : `/${id}/setup`
            }
            className="text-blue-700 px-4 py-6 block hover:bg-gray-100 border-b border-gray-200"
          >
            {title}
            {createdAt && (
              <span className="text-gray-500 float-right">
                created {formatDistanceToNow(createdAt.toDate())} ago
              </span>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
}
