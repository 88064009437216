import React, { Fragment, useState, useEffect, useRef } from "react";
import useInterval from "../../hooks/useInterval";
import { useStorage, useCollection } from "../../hooks/useFirebase";
import { formatDistanceToNow, formatISO } from "date-fns";

import useScreenRecorder from "../../hooks/useScreenRecorder";

export default function PerformerRecordButton({ performancePath }) {
  const { upload } = useStorage();
  const { data: performerScreenshots, add: addScreenshot } = useCollection(
    `${performancePath}/performerScreenshots/`,
    {
      limit: 1,
      orderBy: "createdAt",
      desc: true,
    }
  );
  const recentScreenshot = performerScreenshots
    ? performerScreenshots[0]
    : null;

  const {
    isRecording,
    start: startRecording,
    stop: stopRecording,
    captureFrame,
  } = useScreenRecorder();

  const [showScreenshot, setShowScreenshot] = useState(true);

  useInterval(async () => {
    if (isRecording) {
      const blob = await captureFrame();

      // upload the frame
      const createdAt = new Date();
      const id = +createdAt;
      const path = `${performancePath}/performerScreenshots/${id}.png`;
      const screenshot = { id, createdAt, src: URL.createObjectURL(blob) };
      const { url } = await upload({
        data: blob,
        path,
      });
      addScreenshot({ ...screenshot, src: url });
    }
  }, 20000);

  return (
    <Fragment>
      {!isRecording && (
        <button
          className="z-20 fixed bottom-0 left-0 ml-2 mb-2 bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          onClick={startRecording}
        >
          Record Performer Zoom window
        </button>
      )}
      {isRecording && (
        <button
          className="z-20 fixed bottom-0 left-0 ml-2 mb-2 bg-red-500 text-white p-2 rounded hover:bg-red-600"
          onClick={stopRecording}
        >
          Recording
        </button>
      )}
      <button
        className="z-20 fixed bottom-0 right-0 mr-2 mb-2 border border-blue-500 text-blue-900 p-2 rounded"
        onClick={() => setShowScreenshot(!showScreenshot)}
      >
        {showScreenshot ? "Hide" : "Show"} Screenshot
      </button>
      {recentScreenshot && showScreenshot && (
        <img
          className="fixed bottom-0 right-0 w-1/2 opacity-50"
          src={recentScreenshot.src}
          alt=""
        />
      )}
    </Fragment>
  );
}

// Object.values(screenshots)
//   .sort((a, b) => a.createdAt - b.createdAt)
//   .map(({ id, src }) => <img key={id} className="w-32 mr-2" src={src} />);
