import React, { useState, useEffect } from "react";
import { useCollection, useDoc, useFirestore } from "../../hooks/useFirebase";
import Participant from "./Participant";

export default function BlastMosaicPane({ magicianPath, performancePath }) {
  const db = useFirestore();
  const { data: performance } = useDoc(performancePath);
  const { data: participants } = useCollection(
    `${performancePath}/participants/`
  );

  const [phonesByKey, setPhonesByKey] = useState(null);
  const { data: contacts } = useCollection(`${performancePath}/contacts/`);
  useEffect(() => {
    if (!contacts) return;
    const newPhonesByKey = {};
    contacts.forEach((contact) => {
      if (contact && contact.card && contact.card.key) {
        const key = contact.card.key;
        const phoneNumber = contact.from;
        newPhonesByKey[key] = phoneNumber;
      }
    });
    setPhonesByKey(newPhonesByKey);
  }, [contacts]);

  if (!performance || !participants || !contacts) return "Loading...";

  const { mosaicGrid } = performance;
  const { cols, rows, pieceSrcs } = mosaicGrid || { rows: 0, cols: 0 };
  const participantsByIndex = [];
  participants
    .filter((p) => p.index)
    .forEach((p) => {
      if (phonesByKey) p.phoneNumber = phonesByKey[p.cardKey];
      participantsByIndex[p.index - 1] ||
        (participantsByIndex[p.index - 1] = []);
      participantsByIndex[p.index - 1].push(p);
    });

  function blast() {
    participantsByIndex.forEach((participants, i) => {
      const src = pieceSrcs[i];
      participants.forEach(({ phoneNumber }) => {
        const mediaUrls = [src];
        // create a new message
        const messagesPath = `${magicianPath}conversations/${phoneNumber}/messages`;
        console.log({ i, phoneNumber, src });
        db.collection(messagesPath).add({ mediaUrls, createdAt: new Date() });
        // mediaUrls
        // console.log({ phoneNumber, src, messagePath });
      });
    });
  }

  return (
    <div>
      <button
        onClick={blast}
        className="bg-green-500 px-2 py-3 rounded text-white"
      >
        blast
      </button>
      <table className="w-full">
        <tbody>
          {[...Array(rows)].map((_, r) => (
            <tr key={`row-${r}`}>
              {[...Array(cols)].map((_, c) => {
                const i = r * mosaicGrid.cols + c;
                const participants = participantsByIndex[i];
                return (
                  <td key={`r${r}c${c}`} className="border p-2">
                    <img
                      className={``}
                      key={`img-r${r}c${c}`}
                      src={pieceSrcs[i]}
                      alt=""
                    />
                    {participants &&
                      participants.map((p) => (
                        <span key={p.id}>
                          {p.title}: {p.phoneNumber}
                        </span>
                      ))}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
