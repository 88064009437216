import React from "react";
import { Link } from "react-router-dom";

import { formatDistanceToNow } from "date-fns";

export default function ConversationsList({ conversations, magicianId }) {
  if (!conversations || conversations.length === 0)
    return <p>No conversations. Text your number to start one!</p>;
  return (
    <ul className="shadow rounded">
      {conversations.map(({ title, id, createdAt, newestMessage }) => (
        <li key={id}>
          <Link
            to={
              magicianId
                ? `/conversations/${id}?magicianId=${magicianId}`
                : `/conversations/${id}`
            }
            className="text-blue-700 px-4 py-6 block hover:bg-gray-100 border-b border-gray-200"
          >
            {title || id}
            {newestMessage && (
              <p className="text-gray-500">{newestMessage.body}</p>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
}
